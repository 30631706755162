import React from 'react';
import { graphql } from 'gatsby';
import { Document, Page } from 'react-pdf';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Header from '../components/Header';
import ShowcaseGallery from '../components/ShowcaseGallery';
import Video from '../components/Video';

import css from '../css/pages/cyberbiome.module.css';

class cyberbiomePage extends React.Component {
  constructor() {
    super();
  }

  render() {
    const { data } = this.props;
    const treepartyGallery = data.treeparty.edges.map(item => item.node);
    const mdefGallery = data.mdef.edges.map(item => item.node);
    const processGallery = data.process.edges.map(item => item.node);

    return (
      <Layout>
        <SEO title="Cyberbiome" />
        <Header />

        <section className="raised">
          <div className="ta-center mt-2">
            <h1 className={css.title}>Cyberbiome</h1>
            <p className={`${css.subtitle}`}>
              by{' '}
              <a href="https://iljapanic.com" target="_blank">
                Ilja Panic
              </a>
            </p>

            <div className="wrap-xs mt-2">
              <p className={css.intro}>
                An eco-speculative project exploring hybrid spaces for creating synergetic
                relationships between people, machines and natural systems by using new modes of
                inter-species interfaces.
              </p>
            </div>

            <div className="wrap-s">
              <div className={css.teaserVideo}>
                <Video url="https://vimeo.com/342830019" />
              </div>
            </div>

            <div className="wrap-xs mt-2">
              <p>
                The project consists from an interactive installation which represents the first
                ever cyberbiome accompanied by the online platform cyberbiomes.org which curates and
                disseminates projects, concepts and initiatives at the intersection of nature,
                technology and culture.{' '}
              </p>
              <p>
                <a href="https://mdef.iljapanic.com/" target="_blank">
                  <strong>Read accompanying essay</strong>
                </a>
              </p>
            </div>
          </div>
        </section>

        <div className="wrap-xl mt-6">
          {/* tree party */}
          <section className={css.card}>
            <h2 className="ta-center mb-1">2019/07 — Tree party [somewhere in Czech Republic]</h2>
            <p className={`${css.credits} ta-center mt-1 mb-2 raised`}>
              Photos and videos by{' '}
              <a href="https://www.facebook.com/JanRphoto/" target="_blank">
                Jan Rozsypal
              </a>
              , Alien and the author
            </p>

            <div className="raised">
              <ShowcaseGallery photos={treepartyGallery} count={5} />
            </div>

            {/* grid */}
            <div className={css.grid}>
              <div className={css.spanHalf}>
                <Video url="https://vimeo.com/354167510" />
              </div>
              <div className={css.spanHalf}>
                <Video url="https://vimeo.com/354174647" />
              </div>
            </div>
            {/* end grid */}
          </section>
          {/* END tree party */}

          {/* MDEF */}
          <section className={css.card}>
            <h2 className="ta-center mb-3">2019/06 — MDEF final exhibition [Barcelona]</h2>

            <div className="raised">
              <ShowcaseGallery photos={mdefGallery} count={5} />
            </div>
            {/* grid */}
            <div className={css.grid}>
              <div className={css.spanHalf}>
                <Video url="https://vimeo.com/354394405" />
              </div>
              <div className={css.spanHalf}>
                <Video url="https://vimeo.com/354394793" />
              </div>
            </div>
            {/* end grid */}
          </section>
          {/* END MDEF */}

          {/* process */}
          <section className={css.card}>
            <h2 className="ta-center mb-3">2019 — Making process</h2>

            <div className="raised">
              <ShowcaseGallery photos={processGallery} count={4} />
            </div>

            {/* grid */}
            <div className={css.grid}>
              <div className={css.spanHalf}>
                <Video
                  url="https://vimeo.com/343954258"
                  label="CNC milling the platform (timelapse)"
                />
              </div>
              <div className={css.spanHalf}>
                <Video
                  url="https://vimeo.com/353550238"
                  label="polishing the platform (timelapse)"
                />
              </div>
              <div className={css.spanHalf}>
                <Video url="https://vimeo.com/354411299" label="glueing the platform (timelapse)" />
              </div>
              <div className={css.spanHalf}>
                <Video url="https://vimeo.com/354415455" label="projection experimentation" />
              </div>
            </div>
            {/* end grid */}
          </section>
          {/* END process */}

          {/* files */}
          <section className={css.card}>
            <h2 className="mb-2 ta-center">Downloads</h2>
            <div className="wrap-l">
              <ul className={`${css.files} raised`}>
                <li>
                  <a href="/cyberbiome/cyberbiomes-poster_A5.pdf" target="_blank">
                    <div className={css.filePreview}>
                      <Document file="/cyberbiome/cyberbiomes-poster_A5.pdf">
                        <Page pageNumber={1} />
                      </Document>
                    </div>
                    <span className={css.fileLabel}>Annotation [ENG]</span>
                  </a>
                </li>
                <li>
                  <a href="/cyberbiome/cyberbiomes-glossary-A5.pdf" target="_blank">
                    <div className={css.filePreview}>
                      <Document file="/cyberbiome/cyberbiomes-glossary-A5.pdf">
                        <Page pageNumber={2} />
                      </Document>
                    </div>
                    <span className={css.fileLabel}>Glossary Posters [ENG]</span>
                  </a>
                </li>
                <li>
                  <a href="/cyberbiome/cyberbiomes-essay.pdf" target="_blank">
                    <div className={css.filePreview}>
                      <Document file="/cyberbiome/cyberbiomes_essay.pdf">
                        <Page pageNumber={1} />
                      </Document>
                    </div>
                    <span className={css.fileLabel}>Accompanying essay [ENG]</span>
                  </a>
                </li>
              </ul>
            </div>
          </section>
          {/* END files */}
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  {
    mdef: allFile(
      sort: { fields: name, order: ASC }
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { regex: "/(2019-mdef)/" }
      }
    ) {
      edges {
        node {
          id
          name
          extension
          childImageSharp {
            fixed(width: 1920) {
              src
            }
            fluid(maxWidth: 240, maxHeight: 135) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    treeparty: allFile(
      sort: { fields: name, order: ASC }
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { regex: "/(2019-tree)/" }
      }
    ) {
      edges {
        node {
          id
          name
          extension
          childImageSharp {
            fixed(width: 1920) {
              src
            }
            fluid(maxWidth: 240, maxHeight: 135) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    process: allFile(
      sort: { fields: name, order: ASC }
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { regex: "/(2019-process)/" }
      }
    ) {
      edges {
        node {
          id
          name
          extension
          childImageSharp {
            fixed(width: 1920) {
              src
            }
            fluid(maxWidth: 240, maxHeight: 135, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default cyberbiomePage;
